import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Chart} from 'angular-highcharts';
import * as moment from 'moment';
import {SeriesColumnOptions, XAxisOptions, YAxisPlotLinesOptions} from 'highcharts';
import {BehaviorSubject} from 'rxjs';
import {TranslateService} from '@ngx-translate/core';

@Component({
    selector: 'app-real-time-alert-chart',
    templateUrl: './real-time-alert-chart.component.html',
    styleUrls: ['./real-time-alert-chart.component.scss']
})
export class RealTimeAlertChartComponent implements OnInit {
    @Input() isDetail = false;
    @Output() chartLoaded = new EventEmitter<RealTimeAlertChartComponent>(true);
    onLoaded = new BehaviorSubject<boolean>(false);

    chart: Chart = null;

    onlyShowStartEndDates = true;

    constructor(private translate: TranslateService) {
    }

    ngOnInit() {
        this.initializeChart();
    }

    deactivateXAxisLabelLimitation(): void {
        this.onlyShowStartEndDates = false;
    }

    addNewSeries(values: any[], y_attribute: string, remove_series_count = 0, zindex = null): void {
        if (remove_series_count >= 0) {
            this.removeSeries(0, remove_series_count);
        }
        try {
            const s: SeriesColumnOptions = {
                name: null,
                zIndex: 1,
                data: values.map((element) => {
                    return [
                        new Date(element.timestamp).getTime(),
                        element[y_attribute]
                    ];
                }),
                type: 'column'
            };
            if (zindex) {
                s.zIndex = zindex.zindex;
                s.color = zindex.color;
            }
            this.chart.addSeries(s, true, false);
            if (this.onlyShowStartEndDates) {
                const firstDate = new Date(values.first().timestamp).getTime();
                const lastDate = new Date(values.last().timestamp).getTime();
                const tickPositions = [firstDate, lastDate];
                this.chart.ref.update({xAxis: {tickPositions}}, true);
            }
        } catch (exc) {
            console.log('Exception: Malformed data.');
        }
    }

    removeSeries(idx: number, count = -1): void {
        if (count === -1) {
            this.chart.removeSeries(idx);
        } else {
            for (let i = idx; i < idx + count; ++i) {
                if (this.chart !== null && this.chart !== undefined) {
                    this.chart.removeSeries(i);
                }
            }
        }
    }

    reset(): void {
        this.translate.get('screens.dashboard.consumptionAlert.loadingEvaluation').subscribe(text => {
            this.chart.ref.showLoading(text);
        });
    }

    showLoadingState(show: boolean = true): void {
        if (show) {
            if (this.chart) {
                if (this.chart.ref) {
                    this.translate.get('screens.dashboard.consumptionAlert.noEvaluation').subscribe(text => {
                        this.chart.ref.showLoading(text);
                    });
                }
            }
        } else {
            if (!this.chart) {
                return;
            }
            if (this.chart.ref) {
                this.chart.ref.hideLoading();
            }
        }
    }

    insertVerticalLine(time: Date): void {
        const options: XAxisOptions = {
            plotLines: [{color: 'rgba(0,0,0,0.20)', width: 2, value: time.getTime(),}]
        };
        this.chart.ref.update({xAxis: options}, true);
    }

    addHorizontalLine(
        yValue: number,
        color: string = '#1ea2b1',
        iconUrl: string = '',
        iconSize: number = 30
    ): void {
        const iconHtml = `
        <div style="width: ${iconSize}px; height: ${iconSize}px; background-color: ${color}; border-radius: 3px; display: flex; align-items: center; justify-content: center;">
            <img src="${iconUrl}" alt="icon" style="width: ${iconSize}px; height: ${iconSize}px;" onerror="this.style.display='none';"/>
        </div>`;

        const plotLine: YAxisPlotLinesOptions = {
            color,
            width: 2,
            value: yValue,
            zIndex: 10,
            label: {
                align: 'right',
                useHTML: true,
                text: iconHtml,
                x: 0,
                y: -(iconSize / 4),
            },
        };

        this.chart.ref.update({
            yAxis: {
                plotLines: [
                    ...(this.chart.ref.options.yAxis[0]?.plotLines || []),
                    plotLine,
                ],
            },
        }, true, false);
    }


    private initializeChart(): void {
        const self = this;
        this.chart = new Chart({
            chart: {
                type: 'column',
                backgroundColor: 'rgba(255, 255, 255, 0)',
                margin: [30, 0, 50, 40],
                reflow: true,
                events: {
                    load() {
                        self.translate.get('screens.dashboard.comparison.loading').subscribe((text: string) => {
                            self.chart.ref$.subscribe({
                                next: ref => {
                                    ref.showLoading(text);
                                }
                            });
                        });
                        self.onLoaded.next(true);
                        self.chartLoaded.next(self);
                    }
                }
            },
            title: {
                text: null
            },
            xAxis: {
                type: 'datetime',
                showFirstLabel: true,
                showLastLabel: true,
                tickAmount: this.onlyShowStartEndDates ? 2 : null,
                tickInterval: 1000 * 60 * 60 * 2,
                dateTimeLabelFormats: {
                    millisecond: '%H:%M',
                    second: '%H:%M',
                    minute: '%H:%M',
                    hour: '%H:%M',
                    day: '%d.%m',
                    week: '%d.%m',
                    month: '%m.%Y',
                    year: '%Y',
                },
                labels: {
                    style: {
                        fontSize: '13px',
                        fontFamily: 'Innogy light, sans-serif',
                    },
                    formatter: (ref) => {
                        const startoftoday = moment().startOf('day');
                        const parsed = moment(ref.value);
                        const differenceDays = moment(startoftoday).day() - moment(parsed).day();
                        const timeString = parsed.format('HH:mm');
                        if (ref.isFirst || ref.isLast) {
                            let prefix = '';
                            if (differenceDays === 1) {
                                self.translate.get('screens.dashboard.consumptionAlert.yesterday').subscribe(text => {
                                    prefix = text;
                                });
                            } else if (differenceDays === 2) {
                                self.translate.get('screens.dashboard.consumptionAlert.dayBeforeYesterday').subscribe(text => {
                                    prefix = text;
                                });
                            } else if (differenceDays > 2) {
                                prefix = parsed.format('DD.MM.YYYY');
                            }
                            return `${prefix} \n ${timeString}`;
                        }
                        if (!this.onlyShowStartEndDates) {
                            return parsed.format('HH:mm');
                        }
                    },
                    autoRotation: [90], // TODO ng update here thingsfalse,
                    overflow: 'justify'
                },
                crosshair: {
                    width: 1,
                    color: 'rgba(0,0,0,0.6)',
                    zIndex: 100
                },
                minPadding: 0,
                maxPadding: 0
            },
            yAxis: {
                title: {
                    text: 'Watt',
                    align: 'high',
                    offset: 0,
                    rotation: 0,
                    y: -20,
                    x: -8,
                    style: {
                        fontFamily: 'Innogy regular, sans-serif',
                    }
                },
                tickAmount: 4,
                labels: {
                    align: 'right',
                    x: -8,
                    y: 6,
                    step: 1,
                    formatter() {
                        if (this.value >= 1) {
                            return this.value.toLocaleString('de-DE');
                        } else {
                            return null;
                        }
                    },
                    style: {
                        fontSize: '13px',
                        fontFamily: 'Innogy regular, sans-serif',
                    }
                },
                gridZIndex: 10,
                gridLineColor: 'rgba(0,0,0,0.20)'
            },
            tooltip: {
                hideDelay: 0,
                enabled: true,
                animation: false,
                shadow: true,
                padding: 0,
                positioner(boxWidth: number, boxHeight: number, point: any) {
                    if ((point.plotX + this.chart.plotLeft + boxWidth) > this.chart.plotWidth) {
                        // old
                        // x:
                        return {
                            x: (point.plotX + this.chart.plotLeft) - boxWidth - 10,
                            y: point.plotY + (boxHeight / 2)
                        };
                    } else {
                        // old
                        // x: point.plotX + this.chart.plotLeft + 10,
                        return {
                            x: point.plotX + this.chart.plotLeft + 10,
                            y: point.plotY + (boxHeight / 2)
                        };
                    }
                },
                useHTML: true,
                formatter() {
                    const d = moment(this.key).format('DD.MM.YY HH:mm');
                    const style = `style="border-color: ${this.color}"`;
                    const name = `<div class="header">${d}  ${self.translate.instant('screens.dashboard.liveDetailZoomLevels.timeUnit')}</div>`;
                    const value = `<div class="body"> ${this.y.toLocaleString('de-DE')} Watt</div>`;
                    return `<div class="column-callout" ${style}> ${name} ${value}</div>`;
                },
                backgroundColor: 'transparent',
                borderWidth: 0,
                borderRadius: 0
            },
            plotOptions: {
                column: {
                    pointPadding: 0,
                    groupPadding: 0,
                    borderWidth: 0,
                    borderColor: '#ffffff',
                    color: '#B9280A',
                    states: {
                        hover: {
                            enabled: false
                        }
                    }
                },
                series: {
                    showInLegend: false,
                    animation: false,
                    marker: {
                        states: {
                            hover: {
                                enabled: false
                            }
                        }
                    },
                    states: {
                        hover: {
                            enabled: false
                        }
                    }
                }
            },
            series: [
                {
                    data: null,
                    name: null,
                    type: 'column'
                },
            ],
            credits: {
                enabled: false
            }
        });
    }
}

